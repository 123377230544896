import process from "prop-types/prop-types";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import useSWR from "swr";

import { getToken, removeToken } from "../../lib/authentication";
import { auth_fetcher } from "../../lib/fetcher";

export default function Header() {
  const location = useLocation();
  const token = getToken();

  const { data, error } = useSWR(
    `${process.env.REACT_APP_BASE_URL}users/me`,
    auth_fetcher
  );

  if (error)
    if (error.response.status === 401) {
      removeToken();
    } else {
      /* do noting */
    }

  const user = data;

  const smallMenu = [
    user
      ? { name: "Umfrageübersicht", href: "/dashboard" }
      : { name: "Registrieren", href: "/registrieren" },
    user
      ? { name: "Abmelden", href: "/abmelden" }
      : { name: "Anmelden", href: "/anmelden" },
  ];

  const mainMenuDashboard = [
    { name: "Home", href: "/" },
    {
      name: "Umfragen",
      href: "/dashboard",
      subitems: [
        { name: "Umfrageübersicht", href: "/dashboard" },
        { name: "Neue Umfrage erstellen", href: "/dashboard/neue-umfrage" },
        { name: "Umfrage ausfüllen", href: "/umfrage" },
      ],
    },
    { name: "Über FormATool", href: "/uber" },
  ];
  if (user && user.is_staff) {
    mainMenuDashboard
      .find((el) => el.name === "Umfragen")
      .subitems?.push({
        name: "Export",
        href: `${process.env.REACT_APP_BASE_URL}export`,
      });
  }

  const mainMenu = [
    { name: "Home", href: "/" },
    { name: "Umfrage", href: "/umfrage" },
    { name: "Über FormATool", href: "/uber" },
  ];

  return (
    <header className="Header js-Header">
      <p className="visuallyhidden" aria-level="1" role="heading">
        Navigation auf uzh.ch
      </p>
      <div className="Header--top">
        <div className="Header--logo">
          <a className="Logo" href="https://uzh.ch/">
            <img
              src="/assets/uzh_logo.svg"
              alt="Universität Zürich"
              width="208"
              height="92"
            />
          </a>
        </div>
        <div className="Header--mobileButtons">
          <a
            className="Header--search"
            href="/node/pages/nt03/nt03_news_home.html"
          >
            <span className="visuallyhidden">Suche</span>{" "}
            <span className="Icon" data-name="24--search">
              <svg>
                <use xlinkHref="#24--search"></use>
              </svg>
            </span>{" "}
          </a>
          <button
            className="Header--burger js-Header--burger"
            type="button"
            aria-controls="main-nav"
            aria-expanded="false"
          >
            <span className="visuallyhidden">Navigation öffnen/schliessen</span>
            <span className="Header--burger--open">
              <span className="Icon" data-name="24--menu">
                <svg>
                  <use xlinkHref="#24--menu"></use>
                </svg>
              </span>
            </span>
            <span className="Header--burger--close">
              <span className="Icon" data-name="24--close">
                <svg>
                  <use xlinkHref="#24--close"></use>
                </svg>
              </span>
            </span>
          </button>
        </div>
        <h2 className="Header--department">
          <Link className="Header--department--link" to="/">
            FormATool
          </Link>
        </h2>
      </div>
      <div className="Header--bottom js-Header--bottom">
        <div className="Header--bottom--inner">
          <div className="MainNav js-MainNav" id="main-nav">
            <div className="MainNav--service js-Header--service">
              <h2 className="visuallyhidden">Benutzer</h2>
              <nav className="ServiceNav">
                <ul className="ServiceNav--list">
                  {smallMenu.map((menuListItem) => (
                    <li
                      className="ServiceNav--list--item"
                      key={menuListItem.href}
                    >
                      <Link
                        className={`ServiceNav--link ${
                          location.pathname === menuListItem.href
                            ? "is-active"
                            : ""
                        }`}
                        to={menuListItem.href}
                        aria-current="page"
                      >
                        {menuListItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <h2 className="visuallyhidden">Haupt-Navigation</h2>
            <nav className="MainNav--inner">
              <ul className="MainNav--list js-MainNav--list">
                {(token ? mainMenuDashboard : mainMenu).map((menuItem) => (
                  <li
                    className={`MainNav--list--item js-MainNav--item ${
                      location.pathname === menuItem.href ||
                      menuItem.subitems?.some(
                        (subitem) => location.pathname === subitem.href
                      )
                        ? "is-active"
                        : ""
                    }`}
                    key={menuItem.href}
                  >
                    {menuItem.subitems ? (
                      <>
                        <button
                          className="MainNav--link js-MainNav--link"
                          type="button"
                          aria-controls="main-nav_overlay_uid-1"
                          aria-expanded="false"
                        >
                          {menuItem.name}
                          <div className="MainNav--icon">
                            <span
                              className="Icon"
                              data-name="16--link-arrow-sm-right"
                            >
                              <svg>
                                <use xlinkHref="#16--link-arrow-sm-right"></use>
                              </svg>
                            </span>
                          </div>
                        </button>
                        <div
                          className="MainNav--overlay js-MainNav--overlay"
                          id="main-nav_overlay_uid-1"
                        >
                          <div className="MainNav--overlay--scroller">
                            <div className="MainNav--overlay--inner">
                              <div
                                className="MainNav--sub js-MainNav--sub"
                                data-mainnav-index="1"
                              >
                                <div className="MainNav--sub--back">
                                  <button
                                    className="Button js-MainNav--back"
                                    type="button"
                                  >
                                    <span className="Button--inner">
                                      Zurück
                                    </span>
                                    <span className="Button--icon">
                                      <span
                                        className="Icon"
                                        data-name="16--link-arrow-sm-right"
                                      >
                                        <svg>
                                          <use xlinkHref="#16--link-arrow-sm-right"></use>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                                <ul className="MainNav--sub--list">
                                  {menuItem.subitems.map((subitem) => (
                                    <li
                                      className={`MainNav--list--item js-MainNav--subitem ${
                                        location.pathname === subitem.href
                                          ? "is-active"
                                          : ""
                                      }`}
                                      key={subitem.href}
                                    >
                                      <Link
                                        className="MainNav--link"
                                        to={subitem.href}
                                      >
                                        {subitem.name}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                                <button className="js-MainNav--close FocusOnly">
                                  Menü schliessen
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Link className="MainNav--link" to={menuItem.href}>
                        {menuItem.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>

              <ul className="MainNav--list more js-MainNav--more">
                <li className="MainNav--list--item js-MainNav--item more">
                  <button
                    className="MainNav--link js-MainNav--link"
                    aria-controls="main-nav_overlay_uid-38"
                    aria-expanded="false"
                    type="button"
                  >
                    Mehr
                    <div className="MainNav--icon">
                      <span className="Icon" data-name="16--menu">
                        <svg>
                          <use xlinkHref="#16--menu"></use>
                        </svg>
                      </span>
                    </div>
                  </button>
                  <div
                    className="MainNav--overlay js-MainNav--overlay"
                    id="main-nav_overlay_uid-38"
                  >
                    <div className="MainNav--overlay--scroller">
                      <div className="MainNav--overlay--inner">
                        <div
                          className="MainNav--sub js-MainNav--sub"
                          data-mainnav-index="0"
                        >
                          <ul className="MainNav--sub--list js-MainNav--overflow"></ul>
                          <button className="js-MainNav--close FocusOnly">
                            Menü schliessen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
