import { Link } from "react-router-dom";

import Breadcrumbs from "../component/breadcrumbs";
import ContentArea from "../component/ContentArea";
import CustomHelmet from "../component/CustomHelmet";
import Intro from "../component/Intro";
import BaseContainer from "../component/layout/base-container";

export default function DataProtection() {
  return (
    <>
      <CustomHelmet pageTitle="Datenschutz" />
      <Breadcrumbs
        listItems={[
          { name: "Home", url: "/" },
          { name: "Datenschutz", url: "/datenschutz" },
        ]}
      />
      <BaseContainer>
        <Intro title="Datenschutz" />
        <ContentArea>
          <div class="TextImage">
            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Die Universität Zürich (UZH) freut sich über Ihren Besuch auf
                  den Webseiten des zentralen UZH-Webservers (UZH-Webseiten). Da
                  Datenschutz für die UZH ein wichtiges Anliegen ist, möchten
                  wir Sie im Folgenden kurz darüber unterrichten, welche Daten
                  die UZH im Rahmen der geltenden datenschutzrechtlichen
                  Bestimmungen von Besuchern und Nutzern der UZH-Webseiten zu
                  welchem Zweck erhebt, weiter bearbeitet und bekannt gibt, und
                  welche Massnahmen getroffen worden sind, um für die Sicherheit
                  dieser Daten zu sorgen.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2
              class="TextImage--title toggle"
              id="1._Gegenstand_des_Datenschutzes"
            >
              1. Gegenstand des Datenschutzes
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Datenschutz dient dem Schutz der Persönlichkeitsrechte. Er
                  verpflichtet die Datenbearbeiter zu rechtmässigem Handeln und
                  verleiht den betroffenen Personen durchsetzbare Rechte im
                  Hinblick auf die Bearbeitung ihrer Personendaten.
                  Personendaten sind nach &sect; 3 Gesetz über die Information
                  und den Datenschutz des Kantons Zürich (IDG ZH) alle
                  Informationen, die sich auf eine bestimmte oder bestimmbare
                  Person beziehen. Hierunter fallen z.B. Angaben wie Name,
                  Anschrift, Telefonnummer, E-Mail- oder IP-Adresse.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2
              class="TextImage--title toggle"
              id="2._Datenerhebung,_-bearbeitung,_-speicherung"
            >
              2. Datenerhebung, -bearbeitung, -speicherung
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Bei jedem Anfordern resp. Aufrufen einer UZH-Webseite werden
                  folgende Zugriffsdaten erhoben und in einer Webserver-Logdatei
                  auf Servern der UZH gespeichert:
                </p>

                <ul class="type1">
                  <li>
                    die IP-Adresse des anfordernden Rechners (z. B.
                    123.456.97.36)
                  </li>
                  <li>
                    die Seite/Adresse (URL), von der aus die Webseite der UZH
                    angefordert wurde
                  </li>
                  <li>
                    der Pfad und der Name der angeforderten Webseite der UZH
                  </li>
                  <li>
                    das Datum und die Uhrzeit der Anforderung (z. B.
                    [12/Apr/2016:00:00:01 +0200])
                  </li>
                  <li>die übertragene Datenmenge</li>
                  <li>der Zugriffsstatus</li>
                  <li>die Zugriffsart</li>
                  <li>
                    die Beschreibung des verwendeten Webbrowsertyps bzw. des
                    verwendeten Betriebssystems
                  </li>
                  <li>die Session-ID</li>
                  <li>Seriennummer des anfordernden Rechners</li>
                </ul>

                <p>
                  Ein Eintrag im Web-Log könnte beispielsweise wie folgt
                  aussehen:
                  <br />
                  <code>
                    10.26.123.168 - - [28/Apr/2023:10:59:25 +0200] "GET /
                    HTTP/1.1" 200 1344 "-" "Mozilla/5.0 (Macintosh; Intel Mac OS
                    X 10.15; rv:109.0) Gecko/20100101 Firefox/112.0"
                  </code>
                </p>

                <p>
                  Die Bearbeitung dieser Daten erfolgt zu folgenden Zwecken:
                </p>

                <ul>
                  <li>
                    Sicherstellung der Netzinfrastruktur und der technischen
                    Administration
                  </li>
                  <li>Optimierung der Nutzung bestimmter Web-Angebote</li>
                  <li>
                    Identifikation und Nachverfolgung unzulässiger
                    Zugriffsversuche.
                  </li>
                </ul>

                <p>
                  Die Webserver-Logdateien werden ab Beendigung des Zugriffs für
                  einen Zeitraum von 90 Tagen. Danach werden die
                  Webserver-Logdateien automatisch gelöscht, soweit nicht ein
                  erkannter Angriff auf unsere Netzinfrastruktur zu einer zivil-
                  oder strafrechtlichen Verfolgung des Angreifers führt und
                  damit eine weitere Speicherung erforderlich macht.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2
              class="TextImage--title toggle"
              id="3._Einwilligung_in_weitergehende_Datennutzung"
            >
              3. Einwilligung in weitergehende Datennutzung
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Die Nutzung bestimmter Angebote auf den UZH-Webseiten (z.B.
                  Newsletter, Kontaktformulare, Webanwendungen) kann eine
                  weitergehende Erhebung, Bearbeitung und längerfristige
                  Speicherung von Personendaten wie z.B. Name, Anschrift oder
                  E-Mail-Adresse erfordern. Mit Eingabe und Übersendung dieser
                  Daten willigen Sie in deren Bearbeitung ein.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2
              class="TextImage--title toggle"
              id="4._Bekanntgabe_von_Personendaten"
            >
              4. Bekanntgabe von Personendaten
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Personendaten werden ausschliesslich dann an Dritte (z.B. an
                  andere Behörden) bekannt gegeben, soweit dies im Rahmen
                  zwingender Rechtsvorschriften (z.B. behördlichen
                  Aufforderungen, Gerichtsbeschlüssen) oder zum Zweck der
                  Rechts- oder Strafverfolgung (z.B. im Fall von Angriffen auf
                  die UZH-Netzinfrastruktur) erforderlich ist. Eine Bekanntgabe
                  an Dritte zu anderen Zwecken findet nicht statt.
                </p>

                <p>
                  Dessen ungeachtet kann die UZH Dienstleister damit
                  beauftragen, die über die UZH-Webseiten erhoben Daten zu den
                  oben genannten Zwecken weiter zu bearbeiten. Die UZH und die
                  beauftragten Drittdienstleister werden mittels rechtlicher,
                  technischer und organisatorischer Massnahmen dazu
                  verpflichtet, die Einhaltung datenschutzrechtlicher
                  Bestimmungen zu gewährleisten.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2 class="TextImage--title toggle" id="5._Tracking-Einstellungen">
              5. Tracking-Einstellungen
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>FormATool setzt aktuell keine Tracking ein.</p>
                {/*
                  <p>
                  Um die Inhaltsstrukturen und Navigationsmechanismen der
                  Webseiten besser an die Bedürfnisse der Nutzer anpassen zu
                  können, werden die Seitenaufrufe und die angeklickten Elemente
                  innerhalb der Seiten protokolliert und analysiert. Dazu wird
                  die Software Matomo verwendet. Matomo setzt sog.
                  &laquo;Cookies&raquo; ein (zum Einsatz und Unterbinden des
                  Einsatzes solcher Cookies siehe unten Punkt 6.)&nbsp;
                  <span lang="de-DE">
                    Die durch die Cookies erzeugten Nutzungsinformationen
                    einschliesslich ihrer gekürzten IP-Adresse werden
                    nicht&nbsp;an Dritte weitergeleitet, sondern auf&nbsp;
                  </span>
                  Servern der UZH{" "}
                  <span lang="de-DE">
                    zum Zweck der Nutzungsanalyse und der Webseitenoptimierung
                    gespeichert.&nbsp;
                  </span>
                  Die IP-Adressen werden bei diesem Vorgang umge&shy;hend
                  anony&shy;mi&shy;siert. Daher ist es nicht möglich,&nbsp;
                  <span lang="de-DE">
                    die Auswertungsergebnisse einer bestimmten IP-Adresse
                    zuzuordnen oder eine personenbezogene Verhaltensüberwachung
                    durchzuführen.
                  </span>
                </p>*/}
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2 class="TextImage--title toggle" id="6._Cookies">
              6. Cookies
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Beim Aufruf einzelner UZH-Webseiten können sogenannte
                  temporäre Cookies eingesetzt werden. Hierbei handelt es sich
                  um kleine Dateien, die von der UZH-Website, die Sie besuchen,
                  auf Ihrem Rechner zu dem Zweck gespeichert werden, um eine
                  optimale Nutzung der Webseiten zu ermöglichen. Diese
                  temporären Cookies werden mit dem Schliessen des Webbrowsers
                  automatisch gelöscht. Sie können den Einsatz solcher Cookies
                  grundsätzlich unterbinden, indem Sie entsprechende
                  Einstellungen in dem von Ihnen verwendeten Webbrowser
                  vornehmen. Wir weisen allerdings darauf hin, dass das
                  Ablehnen, Sperren oder Deaktivieren von Cookies zu
                  Einschränkungen der Funktion abgerufener UZH-Webseiten führen
                  kann.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2
              class="TextImage--title toggle"
              id="7._Querverweise_(Links)_zu_Webseiten_anderer_Anbieter"
            >
              7. Querverweise (Links) zu Webseiten anderer Anbieter
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Die vorliegende Datenschutzerklärung gilt nur für Web-Seiten
                  der UZH. Soweit über Links der Zugang zu Inhalten anderer
                  Anbieter vermittelt wird, möchten wir Sie bitten, deren
                  Datenschutzerklärungen zu beachten. Die UZH ist weder für den
                  Inhalt noch die Datenschutzpraxis dieser anderen Anbieter
                  verantwortlich. Insbesondere kann die UZH nicht gewährleisten,
                  dass die Inhalte anderer Anbieter frei von Malware sind.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2
              class="TextImage--title toggle"
              id="8._Verwendung_von_Social_Media_Plugins"
            >
              8. Verwendung von Social Media Plugins
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Auf den Webseiten der UZH werden keine Zusatzprogramme in Form
                  von sogenannten &laquo;Social Media Plugins&raquo; verwendet,
                  welche IP-Adressen direkt an die Anbieter sozialer Netzwerke
                  übermitteln. Bei den auf den UZH-Webseiten ersichtlichen
                  Bedienelementen der grafischen Benutzeroberflächen (sogenannte
                  &laquo;Buttons&raquo;) handelt es sich ausschliesslich um
                  Links auf die entsprechenden Anbieter sozialer Netzwerke.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2
              class="TextImage--title toggle"
              id="9._Verwendung_externer_Suchdienste"
            >
              9. Verwendung externer Suchdienste
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Innerhalb des Internet-Angebots der UZH wird
                  &laquo;Google&raquo; als zentraler Suchdienst verwendet. Der
                  eingebundene Suchdienst ermöglicht eine Volltext-Suche nach
                  Inhalten des offiziellen Internet-Angebots der UZH. Der
                  Zugriff auf diese Suchfunktion ist über eine in der Kopfzeile
                  der einzelnen UZH-Webseiten eingebundene Such-Box möglich. Mit
                  der Benutzung der Volltext-Suche und dem damit einhergehenden
                  Aufruf der Suchergebnis-Seite stimmen Sie der Nutzung des
                  Google-Suchdienstes und damit auch der Bekanntgabe von Daten
                  an den Google-Service zu. Bitte beachten Sie, dass für Google
                  eine andere Datenschutzpraxis als für die UZH gilt.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2 class="TextImage--title toggle" id="10._Sicherheit">
              10. Sicherheit
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Die UZH setzt technische und organisatorische
                  Sicherheitsmassnahmen ein, damit die Daten, die sie über die
                  UZH-Webseiten erhebt und weiter bearbeitet,
                </p>

                <ul>
                  <li>
                    vertraulich bleiben und vor zufälligen oder unrechtmässigen
                    Zugriffen, Veränderungen oder Offenlegungen sowie vor
                    Verlusten und Zerstörung geschützt werden; und
                  </li>
                  <li>
                    ein Zugriff auf die Daten ausschliesslich nach dem Grundsatz
                    der Erforderlichkeit (&laquo;Need-to-Know&raquo;) denjenigen
                    Personen erteilt wird, die aufgrund ihrer Funktion und
                    Aufgabe auf die Personendaten zugreifen müssen.
                  </li>
                </ul>

                <p>
                  Die zu treffenden Massnahmen richten sich nach der Art der
                  Information, nach Art und Zweck der Verwendung und nach dem
                  jeweiligen Stand der Technik.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2 class="TextImage--title toggle" id="11._Gültigkeit">
              11. Gültigkeit
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Die UZH behält sich vor, die Datenschutzerklärung jederzeit
                  mit Wirkung für die Zukunft zu ändern, sollte die
                  Implementierung neuer Technologien oder die Gesetzeslage eine
                  solche Anpassung erforderlich machen. Wir empfehlen Ihnen
                  daher, die Datenschutzerklärung regelmässig zu überprüfen.
                </p>

                <p>
                  Soweit auf Webseiten einzelner Organisationseinheiten der UZH
                  von dieser Datenschutzerklärung abgewichen wird, erfolgt ein
                  Hinweis im Impressum auf der Website der Organisationseinheit.
                </p>
              </div>
            </div>
          </div>

          <div class="TextImage">
            <h2 class="TextImage--title toggle" id="12._Auskunftsrecht">
              12. Auskunftsrecht
            </h2>

            <div class="TextImage--inner">
              <div class="TextImage--content richtext">
                <p>
                  Sollten Sie eine Auskunft zu den über Sie erhobenen und weiter
                  bearbeiteten Personendaten oder eine Berichtigung, Vernichtung
                  oder Sperrung dieser Daten wünschen oder weitergehende Fragen
                  zu deren Verwendung haben, wenden Sie sich bitte an die Person
                  im{" "}
                  <Link to="/impressum" target="_blank">
                    Impressum
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </ContentArea>
      </BaseContainer>
    </>
  );
}
