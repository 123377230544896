import { Backdrop, CircularProgress } from "@mui/material";

export default function FullScreenLoader() {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      transitionDuration={1000}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
