import axios from "axios";

import { getToken } from "./authentication";
import { request_methods } from "./request_methods";

export const fetcher = (url) => axios.get(url).then((res) => res.data);

export const auth_fetcher = (url) =>
  axios
    .get(url, {
      headers: { Authorization: `Token ${getToken()}` },
    })
    .then((res) => res.data);

export const custom_fetcher = ([url, method, authentication = true]) => {
  const config = authentication
    ? {
        headers: { Authorization: `Token ${getToken()}` },
      }
    : {};
  return (
    method === request_methods.options
      ? axios.options(url, config)
      : axios.get(url, config)
  ).then((res) => res.data);
};
