const key = "auth-token";

export function setToken(token, keepLogin = false) {
  let store = sessionStorage;
  if (keepLogin) store = localStorage;

  store.setItem(key, token);
}

export function getToken() {
  return sessionStorage.getItem(key) || localStorage.getItem(key); // Either get key from sessionstorage or localstorage.
}

export function removeToken() {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
}
