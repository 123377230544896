import { Link } from "react-router-dom";

export default function Breadcrumbs({ listItems }) {
  return (
    <nav className="Breadcrumb js-Breadcrumb">
      <ol
        className="Breadcrumb--list js-Breadcrumb--list"
        data-js-scroll-style='{"horizontal":true}'
        aria-label="Breadcrumb"
      >
        {listItems.map((listItem, i, arr) => (
          <li className="Breadcrumb--list--item" key={listItem.url}>
            <Link className="Breadcrumb--link" to={listItem.url}>
              {listItem.name}
            </Link>
            {i < arr.length - 1 && (
              <span className="Icon" data-name="16--breadcrumb-separator">
                <svg>
                  <use xlinkHref="#16--breadcrumb-separator"></use>
                </svg>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
