import { Helmet } from "react-helmet-async";

const siteTitle = "FormATool";
const separator = "|";
export default function CustomHelmet({ pageTitle }) {
  if (pageTitle)
    return (
      <Helmet>
        <title>
          {pageTitle} {separator} {siteTitle}
        </title>
      </Helmet>
    );
  else
    return (
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
    );
}
