export default function Footer() {
  const footerLinksLeft = []; // Empty
  const quicklinks = [];

  const metalinks = [
    { name: "Impressum", href: "/impressum" },
    { name: "Datenschutz", href: "#2" },
  ];

  return (
    <footer className="Footer">
      <p className="visuallyhidden" aria-level="1" role="heading">
        Footer
      </p>
      <div className="Footer--main">
        <div className="Footer--column">
          <div className="FooterLinkList">
            <h2 className="FooterLinkList--title">Kontakt</h2>
            <div className="FooterLinkList--text richtext">
              <address>
                Institut für Erziehungswissenschaft
                <br />
                Lehrstuhl für pädagogisch-psychologische Lehr-Lernforschung und
                Didaktik
                <br />
                Universität Zürich
                <br />
                Freiestrasse 36
                <br />
                CH-8032 Zürich
                <br />
                <a
                  className="-ml-[6px] !p-0"
                  href="mailto:formatool@ife.uzh.ch"
                >
                  {"formatool@ife.uzh.ch"}
                </a>
              </address>
            </div>

            <ul className="FooterLinkList--list">
              {footerLinksLeft.map((link) => (
                <li className="FooterLink--item" key={link.href}>
                  <a className="Link size-small" href={link.href}>
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {quicklinks.length > 0 ? (
          <div className="Footer--column">
            <div className="FooterLinkList">
              <h2 className="FooterLinkList--title">Quicklinks</h2>
              <ul className="FooterLinkList--list">
                {quicklinks.map((link) => (
                  <li className="FooterLink--item" key={link.href}>
                    <a className="Link size-small" href={link.href}>
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="Footer--bottom">
        <h2 className="visuallyhidden">Weiterführende Links</h2>
        <div className="Footer--logos">
          <div className="Footer--logos--list">
            <div className="Footer--logos--item">
              <a className="Footer--logo" href="https://www.uzh.ch/">
                <img src="/assets/uzh_logo.svg" alt="Universität Zürich" />
              </a>
            </div>
          </div>
        </div>

        <div className="FooterMeta">
          <div className="FooterMeta--copyright">
            © {new Date().getFullYear()} Universität Zürich
          </div>
          <nav>
            <ul className="FooterMeta--linkList">
              {metalinks.map((link) => (
                <li key={link.href}>
                  <a className="FooterMeta--link" href={link.href}>
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}
