import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/material-icons";

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";

import FullScreenLoader from "./component/FullScreenLoader";
import Template from "./component/layout/template";
import Router from "./pages/router";

function App() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <BrowserRouter>
      <SWRConfig>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
            <HelmetProvider>
              <div className="App">
                <Suspense fallback={<FullScreenLoader />}>
                  <Template>
                    <Router />
                  </Template>
                </Suspense>
              </div>
            </HelmetProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </SWRConfig>
    </BrowserRouter>
  );
}

export default App;
