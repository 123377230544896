import React from "react";

import Breadcrumbs from "../component/breadcrumbs";
import CustomHelmet from "../component/CustomHelmet";
import Intro from "../component/Intro";
import BaseContainer from "../component/layout/base-container";

export default function RegisterDeclined() {
  return (
    <>
      <CustomHelmet pageTitle="Registrierung abgebrochen" />
      <Breadcrumbs
        listItems={[
          { name: "Home", url: "/" },
          { name: "Registrierung", url: "/registrieren" },
          { name: "Abbruch", url: "/registrieren/abbruch" },
        ]}
      />
      <BaseContainer>
        <Intro title="Registrierung Abgebrochen">
          <p>Liebe Lehrperson</p>
          <p>
            Ohne Ihre Einwilligung zur begleitenden Datenerhebung zu
            Forschungszwecken können Sie FormATool leider nicht einsetzen.
          </p>
          <p>
            Herzliche Grüsse <br />
            FormATool-Team an der Universität Zürich
          </p>
        </Intro>
      </BaseContainer>
    </>
  );
}
