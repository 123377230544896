import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import DataProtection from "./dataprotection";
import RegisterDeclined from "./registerDeclined";

const Home = React.lazy(() => import("./home"));
const StartSurvey = React.lazy(() => import("./start_survey"));
const Survey = React.lazy(() => import("./survey"));
const DashboardRouter = React.lazy(() => import("./dashboard/dashboardRouter"));
const Login = React.lazy(() => import("./login"));
const Logout = React.lazy(() => import("./logout"));
const About = React.lazy(() => import("./about"));
const Impressum = React.lazy(() => import("./impressum"));
const Signup = React.lazy(() => import("./signup"));
const Thanks = React.lazy(() => import("./thanks"));
const PasswordChange = React.lazy(() => import("./password_reset/change"));
const RequestPasswordChange = React.lazy(() =>
  import("./password_reset/request")
);

export default function Router() {
  const location = useLocation();
  useEffect(() => {
    document?.querySelector("main")?.click();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="uber" element={<About />} />
      <Route path="impressum" element={<Impressum />} />
      <Route path="datenschutz" element={<DataProtection />} />
      <Route path="registrieren" element={<Signup />} />
      <Route path="registrieren/abbruch" element={<RegisterDeclined />} />
      <Route path="anmelden" element={<Login />} />
      <Route path="abmelden" element={<Logout />} />
      <Route path="dashboard/*" element={<DashboardRouter />} />
      <Route path="danke/*" element={<Thanks />} />
      <Route path="umfrage" element={<StartSurvey />} />
      <Route path="umfrage/:tan" element={<Survey />} />
      <Route path="passwort" element={<RequestPasswordChange />} />
      <Route path="passwort/:token" element={<PasswordChange />} />
    </Routes>
  );
}
