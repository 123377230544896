export default function ContentArea({ children, className, ...params }) {
  return (
    <section
      className={`ContentArea ${className ? className : ""}`}
      {...params}
    >
      {children}
    </section>
  );
}
