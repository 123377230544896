import React from "react";

export default function BaseContainer({
  children,
  className,
  oversize = false,
}) {
  return (
    <div className={`mui-btn-wrapper flex flex-col items-center ${className}`}>
      <div
        className={
          !oversize
            ? "w-full max-w-[907px] px-4 sm:px-10"
            : "w-full max-w-[1520px] px-4 sm:px-10"
        }
      >
        {children}
      </div>
    </div>
  );
}
