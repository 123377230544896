import React from "react";

export default function Intro({ title, children, ...params }) {
  return (
    <section className="mb-[64px] mt-[80px]" {...params}>
      <div>
        <h1 className="Intro--title mb-8">{title}</h1>
        {children}
      </div>
    </section>
  );
}
